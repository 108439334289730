import cn from "classnames"
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Imgix from "react-imgix"
import Slider from "react-slick"
import Button from "../components/button"
import CtaBanner from "../components/ctaBanner"
import GalleryModal from "../components/galleryModal"
import IndustrialFaqs from "../components/industrialFaqs"
import ProductButtons from "../components/newProductButtons"
import Rating from "../components/rating"
import RelatedBlogPostsNew from "../components/relatedBlogPostNew"
import RelatedCaseStudies from "../components/relatedCaseStudies"
import SEO from "../components/seo"
import Title from "../components/title"
import VideoBlock from "../components/videoBlock"
import VideoImageBlock from "../components/videoImageBlock"
import bvReviewDataJSON from "../data/bvreviews.json"
import NewLayout from "../layout/newLayout"
import { supSub } from "../services/util"
import styles from "../styles/scss/templates/industrial-page.module.scss"

import {
    carouselProductImages,
    collapseProductImages,
    faqs,
    images,
    productCopy,
    productIcons,
    productImages,
    productVideos
} from "../../static/img/industrial-page/industrialPageData"

const IndustrialPage = ({ ...props }) => {
    // Page Context
    const pageData = props.pageContext.page;    
    const blogs = pageData.relatedArticles;
    const caseStudies = pageData.relatedPressReleases;

    //check for mobile widths
    const [isMobile, setIsMobile] = useState(false)

    const [active, setActive] = useState(-1);

    const handleActive = (e) => {
        setActive(active === e ? -1 : e);
    };

    const handleResize = () => {
        if (window.innerWidth <= 576) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const firstContent = {
        video: pageData.content[0].video,
        title: pageData.content[0].title,
        body: pageData.content[0].body,
    }

    const secondSectionContent = {
        sectionTitle: pageData.content[1].subtitle,
        body: pageData.content[1].body,
    }

    const thirdSectionContent = {
        title: pageData.content[2].title,
        body: pageData.content[2].body,
        image: pageData.content[2].image,
    }

    const heroSectionContent = {
        title: pageData.content[3].title,
        image: pageData.content[3].image,
    }

    const productSampleSectionContent = {
        title: pageData.content[4].title,
        image: pageData.content[4].image,
    }

    // End of Page Context

    //Associate product reviews with industrial products to get reviews.
    const productReviews = id => {
        return bvReviewDataJSON.find(review => review.id === id)
    }

    //Create Final Product Data to consume
    const industrialProducts = pageData.relatedProducts.map((item, index) => {
        return {
            productCollapseItem: collapseProductImages(index),
            productIcon: productIcons(index),
            productReview: productReviews(item.bazaarVoiceId),
            productCopy: productCopy(index),
            productImages: productImages(index),
            productVideos: productVideos(index),
            ...item,
        }
    })

    const scrollToSection = (e, sectionId) => {
        e.preventDefault()
        e.stopPropagation()
        const element = window.document.getElementById(sectionId)
        if (element) {
            element.scrollIntoView({ block: "center", behavior: "smooth" })
        }
    }

    // State Based & Ref
    const [selectedProductIndex, setSelectedProductIndex] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [selectedSubIndex, setSelectedSubIndex] = useState(0);
    const [selected, setSelected] = useState("");
    const [imgHover, setImgHover] = useState("");

    let sliderRef = useRef(null)
    let sliderRefMovil = useRef(null)

    // End of State Based

    const handleArrowClick = (type, images, lastIndex) => {
        if (lastIndex === 0 && type === "previous") return
        const index = type === "next" ? lastIndex + 1 : lastIndex - 1
        if (index < images.length && index >= 0) {
            const selectedSubIndex =
                images[index] !== "undefined" ? index : lastIndex
            const selected = images[selectedSubIndex].url
                ? images[selectedSubIndex].url
                : images[selectedSubIndex].productVideos.thumbnailUrl
            setSelectedSubIndex(selectedSubIndex);
            setSelected(selected);
        }
        const sliderContainer = document.getElementById("SliderImagesContainer");

        if (sliderContainer) {
            if (type === "next") {
                sliderContainer.scrollLeft += 500;
            } else {
                sliderContainer.scrollLeft -= 500;
            }
        }
    }

    const handleClick = (selected, selectedSubIndex) => {
        setSelectedSubIndex(selectedSubIndex);
        setSelected(selected);
    }

    const setImageToModal = (item, index) => {
        setCurrentIndex(index)
        setIsModalOpen(isModalOpen)
    }

    const handleMouseEnter = (imgHover) => {
        setImgHover(imgHover);
    }

    const handleMouseLeave = () => {
        setImgHover("");
    }

    // Carousel Functions
    const handleProductChange = (index, variant) => {
        if (variant === "right") {
            let newIndex = index + 8

            if (sliderRef) {
                const slick = sliderRef.current
                const slickMovil = sliderRefMovil.current

                slick.slickGoTo(newIndex)
                slickMovil.slickGoTo(newIndex)
            }
        } else {
            if (sliderRef) {
                const slick = sliderRef.current
                const slickMovil = sliderRefMovil.current

                slick.slickGoTo(index)
                slickMovil.slickGoTo(index)
            }
        }
    }
    const arrowsControl = (productIndex, flow) => {
        if (sliderRef) {
            const slidePosition =
                flow === "next"
                    ? productIndex === 15
                        ? 0
                        : productIndex + 1
                    : productIndex === 0
                    ? 15
                    : productIndex - 1
            handleProductChange(slidePosition)
            setSelectedSubIndex(0)
        }
    }
    const ProductCanourStoryArrow = props => {
        let className = props.type === "next" ? "nextArrow" : "prevArrow"
        className += " arrow"
        const char =
            props.type === "next" ? (
                <i
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ right: "5%" }}
                />
            ) : (
                <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ left: "5%" }}
                />
            )
        return (
            <span
                role="button"
                tabIndex={0}
                onKeyDown={() => arrowsControl(props.productIndex, props.type)}
                className={className}
                onClick={() => arrowsControl(props.productIndex, props.type)}
            >
                {char}
            </span>
        )
    }
    const productCansCarouselsettings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "10px",
        mobileFirst: true,
        swipe: false,
        initialSlide: selectedProductIndex,
        nextArrow: (
            <ProductCanourStoryArrow
                type="next"
                productIndex={selectedProductIndex}
            />
        ),
        prevArrow: (
            <ProductCanourStoryArrow
                type="prev"
                productIndex={selectedProductIndex}
            />
        ),
        beforeChange: function(old, index) {
            setSelectedProductIndex(index)
        },
        responsive: [
            {
                breakpoint: 816,
                settings: {
                    initialSlide: selectedProductIndex,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    infinite: false,
                    centerMode: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    initialSlide: selectedProductIndex,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    infinite: false,
                    centerMode: false,
                },
            },
        ],
    }
    const productCansCarouselsettingsMovil = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "10px",
        mobileFirst: true,
        swipe: false,
        initialSlide: selectedProductIndex,
        nextArrow: (
            <ProductCanourStoryArrow
                type="next"
                productIndex={selectedProductIndex}
            />
        ),
        prevArrow: (
            <ProductCanourStoryArrow
                type="prev"
                productIndex={selectedProductIndex}
            />
        ),
        beforeChange: function(old, index) {
            setSelectedProductIndex(index)
        },
    }
    // End of Carousel Functions

    return (
        <NewLayout>
            <SEO page={pageData} />
            <div className={styles.industrialWrapper}>
                <div
                    className={styles.hero}
                    style={{
                        backgroundImage: `url(${
                            isMobile
                                ? "/img/industrial-page/industrial-hero-mobile.png"
                                : heroSectionContent.image.url
                        })`,
                    }}
                >
                    <h2 className={styles.heroTitle}>
                        {heroSectionContent.title}
                    </h2>
                    <Button
                        text="REQUEST SITE ASSESSMENT"
                        url="/contact-us?assessment_offer=true"
                        className={styles.productButton}
                        target="_self"
                        red
                        rel="noopener noreferrer"
                    />
                </div>

                <div className={styles.videoWrapper}>
                    <div className={styles.videoSection}>
                        <h2 className={styles.videoTitle}>
                            {firstContent.title}
                        </h2>
                        <div
                            className={cn(styles.videoDescription)}
                            dangerouslySetInnerHTML={{
                                __html: supSub(firstContent.body),
                            }}
                        />
                        <Button
                            text="SEARCH PRODUCTS"
                            url="https://www.wd40.com/products/?category=specialist&functionality=industrial-products"
                            className={styles.button}
                            target="_self"
                            red
                            rel="noopener noreferrer"
                        />
                    </div>
                    <div className={styles.iframeContainer}>
                        <VideoBlock
                            id={firstContent.video.id}
                            youtubeVideoId={firstContent.video.providerUid}
                            image={firstContent.video.thumbnailUrl}
                            containerClass={styles.videoBlock}
                            imageClass={styles.videoImage}
                        />
                    </div>
                </div>

                <div className={styles.applicationWrapper}>
                    <h2 className={styles.applicationTitle}>
                        {secondSectionContent.sectionTitle}
                    </h2>
                    <div
                        className={cn(styles.applicationDescription)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(secondSectionContent.body),
                        }}
                    />
                    {images && (
                        <div className={styles.imageGroup}>
                            {images.map((item, index) => (
                                <div className={styles.overlayGroup}>
                                    <div
                                        key={index}
                                        className={styles.imageItem}
                                        style={{
                                            backgroundImage: `url(${item.src})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover"
                                        }}
                                    >
                                        <h2 className={styles.imageTitle}>
                                            {item.title}
                                        </h2>
                                        {/* <Button
                                            text="LEARN MORE"
                                            className={styles.imageButton}
                                            blue
                                            onClick={e =>
                                                scrollToSection(e, "product")
                                            }
                                        /> */}
                                    </div>
                                    <div className={styles.overlaynew} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div
                    className={styles.product}
                    style={{
                        backgroundImage: `url(${thirdSectionContent.image.url})`,
                    }}
                >
                    <h2 className={styles.productTitle}>
                        {thirdSectionContent.title}
                    </h2>
                    <div
                        className={cn(styles.productDescription)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(thirdSectionContent.body),
                        }}
                    />
                    <Button
                        text="ASK A QUESTION"
                        url="/industrial/technicalspecialist/"
                        className={styles.productButton}
                        target="_self"
                        red
                        rel="noopener noreferrer"
                    />
                </div>

                <CtaBanner
                    text="GET THE WD-40 INDUSTRIAL GUIDE"
                    buttonText="Download"
                    buttonUrl="https://files.wd40.com/pdf/industrial/industrial-guide.pdf?_gl=1*falnu7*_gcl_au*MjAwMDc2ODM2MC4xNzE3NTE3Njcx*_ga*MjA5MTc3MjE3OC4xNzE3NTE3Njcx*_ga_DDQGDT9LXF*MTcyMTQwNzcwOS4xNS4xLjE3MjE0MTMzMjcuMjQuMC4w"
                />

                <div className={styles.carouselContainer} id="product">
                    <div className={styles.carouselWrapper}>
                        <div className={styles.carouselImageContainer}>
                            <Title
                                className={styles.carouselTitle}
                                value="Discover Industrial Solutions"
                            />
                            <div className={styles.divider} />
                            <div className="slider-specialist-desktop">
                                <Slider
                                    ref={sliderRef}
                                    {...productCansCarouselsettings}
                                    className={styles.productCansCarousel}
                                >
                                    {carouselProductImages &&
                                        carouselProductImages.map(
                                            (item, index) => {
                                                let canClass = "industrialProductCan" + index;                                                
                                                const selectedImage = imgHover || (selectedSubIndex !== 0 && selected) || (selectedSubIndex === 0 && item.src);

                                                return (
                                                    <div
                                                        className={cn(
                                                            styles.blueCanImageContainer,
                                                            canClass
                                                        )}
                                                        key={index}
                                                    >
                                                        <div
                                                            className={
                                                                styles.imageGap
                                                            }
                                                        >
                                                            <Imgix
                                                                src={selectedImage}
                                                                alt={item.alt}
                                                                sizes="100vm"
                                                                htmlAttributes={{
                                                                    alt:
                                                                        item.alt,
                                                                }}
                                                                className={
                                                                    item.id ===
                                                                    selectedProductIndex
                                                                        ? styles.productCansCarouselImageSelected
                                                                        : styles.productCansCarouselImage
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.productContents
                                                                }
                                                            >
                                                                <svg
                                                                    onClick={() => handleArrowClick(
                                                                        "previous",
                                                                        industrialProducts[selectedProductIndex].productImages,
                                                                        selectedSubIndex
                                                                    )}
                                                                    className={styles.customArrowsSvg}
                                                                    width="18"
                                                                    height="28"
                                                                    viewBox="0 0 18 28"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17 2L3 14L17 26"
                                                                        stroke="#1C3687"
                                                                        strokeWidth="3"
                                                                    />
                                                                </svg>
                                                                <div 
                                                                    id="SliderImagesContainer"
                                                                    className={styles.sliderContent}
                                                                >
                                                                    {industrialProducts[selectedProductIndex].productImages &&
                                                                        industrialProducts[selectedProductIndex].productImages.map((item, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                className={(selected && idx === selectedSubIndex) || (!selected && idx === 0) ? styles.initSelected : styles.selected}
                                                                                onMouseEnter={() => handleMouseEnter(item.url)}
                                                                                onMouseLeave={() => handleMouseLeave()}
                                                                                onKeyDown={() => handleClick(item.url, idx)}
                                                                                onClick={() => handleClick(item.url, idx)}
                                                                            >
                                                                                <Imgix
                                                                                    sizes="100vm"
                                                                                    style={{cursor: "pointer",}}
                                                                                    src={item.url}
                                                                                    alt="product cans"
                                                                                    htmlAttributes={{alt: "product cans",}}
                                                                                    // onClick={() => setImageToModal(item, idx)}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {industrialProducts[selectedProductIndex].productVideos &&
                                                                        industrialProducts[selectedProductIndex].productVideos.map((item, index) => {
                                                                            return (
                                                                                <VideoImageBlock
                                                                                    key={index}
                                                                                    imageClass={styles.videoImage}
                                                                                    containerClass={styles.videoContainer}
                                                                                    hideBody
                                                                                    youtubeVideoId={item.video.providerUid}
                                                                                    image={item.thumbnail}
                                                                                />
                                                                            )}
                                                                        )
                                                                    }
                                                                </div>
                                                                <svg
                                                                    onClick={() => handleArrowClick(
                                                                        "next",
                                                                        industrialProducts[selectedProductIndex].productImages,
                                                                        selectedSubIndex
                                                                    )}
                                                                    className={styles.customArrowsSvg}
                                                                    width="18"
                                                                    height="28"
                                                                    viewBox="0 0 18 28"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M1 26L15 14L0.999998 2"
                                                                        stroke="#1C3687"
                                                                        strokeWidth="3"
                                                                    />
                                                                </svg>

                                                                {isModalOpen && (
                                                                    <GalleryModal
                                                                        images={
                                                                            industrialProducts[
                                                                                selectedProductIndex
                                                                            ]
                                                                                .productImages
                                                                        }
                                                                        selectedProductIndex={
                                                                            selectedProductIndex
                                                                        }
                                                                        videos={
                                                                            industrialProducts[
                                                                                selectedProductIndex
                                                                            ]
                                                                                .productVideos
                                                                        }
                                                                        isModalOpen={
                                                                            isModalOpen
                                                                        }
                                                                        setCurrentIndex={
                                                                            setCurrentIndex
                                                                        }
                                                                        currentIndex={
                                                                            currentIndex
                                                                        }
                                                                        setIsModalOpen={
                                                                            setIsModalOpen
                                                                        }
                                                                    />
                                                                )}                                                                
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.productsDetails
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.leftColumn
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.titleContainer
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.leftContent
                                                                        }
                                                                    >
                                                                        {industrialProducts[
                                                                            selectedProductIndex
                                                                        ]
                                                                            .displayName && (
                                                                            <Title
                                                                                value={
                                                                                    industrialProducts[
                                                                                        selectedProductIndex
                                                                                    ]
                                                                                        .displayName
                                                                                }
                                                                                className={
                                                                                    styles.productTitle
                                                                                }
                                                                            />
                                                                        )}
                                                                        {industrialProducts[
                                                                            selectedProductIndex
                                                                        ].subtitle && (
                                                                            <Title
                                                                                value={
                                                                                    industrialProducts[
                                                                                        selectedProductIndex
                                                                                    ].subtitle
                                                                                }
                                                                                className={
                                                                                    styles.productSubTitle
                                                                                }
                                                                            />
                                                                        )}
                                                                        {industrialProducts[
                                                                            selectedProductIndex
                                                                        ]
                                                                            .productReview && (
                                                                            <div
                                                                                className={
                                                                                    styles.ratingContainer
                                                                                }
                                                                            >
                                                                                <Link 
                                                                                    to={'/products/'+industrialProducts[selectedProductIndex].slug}
                                                                                    className={styles.rating}
                                                                                >
                                                                                    <Rating
                                                                                        value={
                                                                                            industrialProducts[
                                                                                                selectedProductIndex
                                                                                            ]
                                                                                                .productReview
                                                                                        }
                                                                                        bold
                                                                                        spacing
                                                                                        newStyle
                                                                                    />
                                                                                </Link>                                                                                
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.rightColumn
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.productIconsContainer
                                                                            }
                                                                        >
                                                                            <Title
                                                                                value="Works great on:"
                                                                                className={
                                                                                    styles.iconTitle
                                                                                }
                                                                            />
                                                                            <div
                                                                                className={
                                                                                    styles.productIcons
                                                                                }
                                                                            >
                                                                                {industrialProducts[
                                                                                    selectedProductIndex
                                                                                ]
                                                                                    .productIcon &&
                                                                                    industrialProducts[
                                                                                        selectedProductIndex
                                                                                    ].productIcon.map(
                                                                                        (
                                                                                            item,
                                                                                            index
                                                                                        ) => (
                                                                                            <div
                                                                                                className={
                                                                                                    styles.productIndividualIcon
                                                                                                }
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                <Imgix
                                                                                                    sizes="100vm"
                                                                                                    className={
                                                                                                        styles.productImage
                                                                                                    }
                                                                                                    src={
                                                                                                        item[0]
                                                                                                    }
                                                                                                    htmlAttributes={{
                                                                                                        alt:
                                                                                                            "",
                                                                                                    }}
                                                                                                />
                                                                                                <Title
                                                                                                    className={
                                                                                                        styles.productIconTitle
                                                                                                    }
                                                                                                    value={
                                                                                                        item[1]
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {industrialProducts[
                                                                    selectedProductIndex
                                                                ].productCopy
                                                                    .body && (
                                                                    <div
                                                                        className={
                                                                            styles.productInformationWysiwyg
                                                                        }
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: supSub(
                                                                                industrialProducts[
                                                                                    selectedProductIndex
                                                                                ]
                                                                                    .productCopy
                                                                                    .body
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                                <ProductButtons
                                                                    product={
                                                                        industrialProducts[
                                                                            selectedProductIndex
                                                                        ]
                                                                    }
                                                                    verticalButtons={
                                                                        true
                                                                    }
                                                                    withoutAnchor={
                                                                        true
                                                                    }
                                                                    wtbOption = "BNI-SS"
                                                                    displayName="Where to buy"
                                                                />
                                                                <div
                                                                    className={
                                                                        styles.BelowRightColumn
                                                                    }
                                                                >
                                                                    <Link
                                                                        to={
                                                                            "/products/"
                                                                        }
                                                                        className={
                                                                            styles.productRedAnchor
                                                                        }
                                                                    >
                                                                        Explore
                                                                        All
                                                                        WD-40
                                                                        Brand
                                                                        Products{" "}
                                                                        {">>"}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                </Slider>
                            </div>
                            <div className="slider-specialist-movil">
                                <Slider
                                    ref={sliderRefMovil}
                                    {...productCansCarouselsettingsMovil}
                                    className={styles.productCansCarousel}
                                >
                                    {carouselProductImages &&
                                        carouselProductImages.map(
                                            (item, index) => {
                                                const selectedImage = imgHover || (selectedSubIndex !== 0 && selected) || (selectedSubIndex === 0 && item.src);
                                                
                                                return (
                                                    <div
                                                        className={
                                                            styles.mobileBlueCanImageContainer
                                                        }
                                                        key={index}
                                                    >
                                                        <div
                                                            className={
                                                                styles.mobileTitleContent
                                                            }
                                                        >
                                                            {industrialProducts[
                                                                selectedProductIndex
                                                            ].displayName && (
                                                                <Title
                                                                    value={
                                                                        industrialProducts[
                                                                            selectedProductIndex
                                                                        ]
                                                                            .displayName
                                                                    }
                                                                    className={
                                                                        styles.mobileProductTitle
                                                                    }
                                                                />
                                                            )}
                                                            {industrialProducts[
                                                                selectedProductIndex
                                                            ].subtitle && (
                                                                <Title
                                                                    value={
                                                                        industrialProducts[
                                                                            selectedProductIndex
                                                                        ].subtitle
                                                                    }
                                                                    className={
                                                                        styles.mobileProductSubTitle
                                                                    }
                                                                />
                                                            )}
                                                            {industrialProducts[
                                                                selectedProductIndex
                                                            ].productReview && (
                                                                <div
                                                                    className={
                                                                        styles.ratingMobileContainer
                                                                    }
                                                                >
                                                                    <Rating
                                                                        value={
                                                                            industrialProducts[
                                                                                selectedProductIndex
                                                                            ]
                                                                                .productReview
                                                                        }
                                                                        bold
                                                                        spacing
                                                                        newStyle
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.mobileImageGap
                                                            }
                                                        >
                                                            <Imgix
                                                                src={selectedImage}
                                                                alt={item.alt}
                                                                htmlAttributes={{
                                                                    alt: item.alt,
                                                                }}
                                                                sizes="100vm"
                                                                className={
                                                                    item.id ===
                                                                    selectedProductIndex
                                                                        ? styles.productCansCarouselImageSelected
                                                                        : styles.productCansCarouselImage
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.mobileProductContents
                                                                }
                                                            >
                                                                <svg
                                                                    onClick={() => handleArrowClick(
                                                                        "previous",
                                                                        industrialProducts[selectedProductIndex].productImages,
                                                                        selectedSubIndex
                                                                    )}
                                                                    className={styles.customArrowsSvg}
                                                                    width="18"
                                                                    height="28"
                                                                    viewBox="0 0 18 28"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17 2L3 14L17 26"
                                                                        stroke="#1C3687"
                                                                        strokeWidth="3"
                                                                    />
                                                                </svg>
                                                                <div 
                                                                    // id={"SliderImagesContainer"}
                                                                    className={styles.sliderContent}
                                                                >
                                                                    {industrialProducts[selectedProductIndex].productImages &&
                                                                        industrialProducts[selectedProductIndex].productImages.map((item, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                className={(selected && idx === selectedSubIndex) || (!selected && idx === 0) ? styles.initSelected : styles.selected}
                                                                                onMouseEnter={() => handleMouseEnter(item.url)}
                                                                                onMouseLeave={() => handleMouseLeave()}
                                                                                onKeyDown={() => handleClick(item.url, idx)}
                                                                                onClick={() => handleClick(item.url, idx)}
                                                                            >
                                                                                <Imgix
                                                                                    sizes="100vm"
                                                                                    style={{cursor:"pointer",}}
                                                                                    src={item.url}
                                                                                    alt="product cans"
                                                                                    htmlAttributes={{alt: "product cans",}}
                                                                                    // onClick={() =>
                                                                                    //     setImageToModal(
                                                                                    //         item,
                                                                                    //         index
                                                                                    //     )
                                                                                    // }
                                                                                />
                                                                            </div>
                                                                            )
                                                                        )
                                                                    }
                                                                    {industrialProducts[selectedProductIndex].productVideos &&
                                                                        industrialProducts[selectedProductIndex].productVideos.map((item, index ) => {
                                                                                return (
                                                                                    <VideoImageBlock
                                                                                        key={index}
                                                                                        imageClass={styles.videoImage}
                                                                                        containerClass={styles.videoContainer}
                                                                                        hideBody
                                                                                        youtubeVideoId={item.video.providerUid}
                                                                                        image={item.thumbnail}
                                                                                    />
                                                                                )
                                                                            }
                                                                        )
                                                                    }
                                                                </div>
                                                                <svg
                                                                    onClick={() => handleArrowClick(
                                                                        "next",
                                                                        industrialProducts[selectedProductIndex].productImages,
                                                                        selectedSubIndex
                                                                    )}
                                                                    className={styles.customArrowsSvg}
                                                                    width="18"
                                                                    height="28"
                                                                    viewBox="0 0 18 28"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M1 26L15 14L0.999998 2"
                                                                        stroke="#1C3687"
                                                                        strokeWidth="3"
                                                                    />
                                                                </svg>
                                                                {isModalOpen && (
                                                                    <GalleryModal
                                                                        images={
                                                                            industrialProducts[
                                                                                selectedProductIndex
                                                                            ]
                                                                                .productImages
                                                                        }
                                                                        selectedProductIndex={
                                                                            selectedProductIndex
                                                                        }
                                                                        videos={
                                                                            industrialProducts[
                                                                                selectedProductIndex
                                                                            ]
                                                                                .productVideos
                                                                        }
                                                                        isModalOpen={
                                                                            isModalOpen
                                                                        }
                                                                        setCurrentIndex={
                                                                            setCurrentIndex
                                                                        }
                                                                        currentIndex={
                                                                            currentIndex
                                                                        }
                                                                        setIsModalOpen={
                                                                            setIsModalOpen
                                                                        }
                                                                    />
                                                                )}                                                                
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.mobileProductsDetails
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.mobileLeftColumn
                                                                }
                                                            >
                                                                {industrialProducts[
                                                                    selectedProductIndex
                                                                ].productCopy
                                                                    .body && (
                                                                    <div
                                                                        className={
                                                                            styles.productInformationWysiwyg
                                                                        }
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: supSub(
                                                                                industrialProducts[
                                                                                    selectedProductIndex
                                                                                ]
                                                                                    .productCopy
                                                                                    .body
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                                <ProductButtons
                                                                    product={
                                                                        industrialProducts[
                                                                            selectedProductIndex
                                                                        ]
                                                                    }
                                                                    verticalButtons={
                                                                        true
                                                                    }
                                                                    withoutAnchor={
                                                                        true
                                                                    }
                                                                    displayName="Where to buy"
                                                                />
                                                                <div
                                                                    className={
                                                                        styles.mobileBelowRightColumn
                                                                    }
                                                                >
                                                                    <Link
                                                                        to={
                                                                            "/products/"
                                                                        }
                                                                        className={
                                                                            styles.productRedAnchor
                                                                        }
                                                                    >
                                                                        Explore All
                                                                        WD-40 Brand
                                                                        Products{" "}
                                                                        {">>"}
                                                                    </Link>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.mobileTitleContainer
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.mobileRightColumn
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.mobileProductIconsContainer
                                                                            }
                                                                        >
                                                                            <Title
                                                                                value="Works great on:"
                                                                                className={
                                                                                    styles.mobileIconTitle
                                                                                }
                                                                            />
                                                                            <div
                                                                                className={
                                                                                    styles.productIcons
                                                                                }
                                                                            >
                                                                                {industrialProducts[
                                                                                    selectedProductIndex
                                                                                ]
                                                                                    .productIcon &&
                                                                                    industrialProducts[
                                                                                        selectedProductIndex
                                                                                    ].productIcon.map(
                                                                                        (
                                                                                            item,
                                                                                            index
                                                                                        ) => (
                                                                                            <div
                                                                                                className={
                                                                                                    styles.productIndividualIcon
                                                                                                }
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                <Imgix
                                                                                                    sizes="100vm"
                                                                                                    className={
                                                                                                        styles.productImage
                                                                                                    }
                                                                                                    src={
                                                                                                        item[0]
                                                                                                    }
                                                                                                    htmlAttributes={{
                                                                                                        alt:
                                                                                                            "",
                                                                                                    }}
                                                                                                />
                                                                                                <Title
                                                                                                    className={
                                                                                                        styles.productIconTitle
                                                                                                    }
                                                                                                    value={
                                                                                                        item[1]
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )}
                                        )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={styles.hero}
                    style={{
                        backgroundImage: `url(${productSampleSectionContent.image.url})`,
                    }}
                >
                    <h2 className={styles.heroTitle}>
                        {productSampleSectionContent.title}
                    </h2>
                    <Button
                        text="REQUEST SAMPLES"
                        url="/industrial/samplesrequest"
                        className={styles.productButton}
                        target="_self"
                        red
                        rel="noopener noreferrer"
                    />
                </div>

                {caseStudies && 
                    <RelatedCaseStudies blogs={caseStudies} isCustom={false} />
                }

                <CtaBanner
                    text="DISCOVER SOLUTIONS FOR YOUR FACILITY"
                    buttonText="LEARN MORE"
                    buttonUrl="https://www.datocms-assets.com/10845/1725637043-facility-full-of-uses.pdf"
                />

                {blogs && 
                    <RelatedBlogPostsNew blogs={blogs} isCustom={false} />
                }

                {faqs && (
                    <div className={styles.faqMainContainer}>
                        <Title className={styles.faqTitle} value="Frequently Asked Questions" />
                        <div className={styles.cardsGroup}>
                            <div className={styles.cardsContainer}>
                                {faqs.map((item, index) => (
                                    index % 2 === 0 && <IndustrialFaqs 
                                        key={index}
                                        question={item.question}
                                        answer={item.answer}
                                        index={index}
                                        active={active}
                                        setActive={handleActive}
                                    />
                                ))}
                            </div>
                            <div className={styles.cardsContainer}>
                                {faqs.map((item, index) => (
                                    index % 2 !== 0 && <IndustrialFaqs 
                                        key={index}
                                        question={item.question}
                                        answer={item.answer}
                                        index={index}
                                        active={active}
                                        setActive={handleActive}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <style>
                {`
                    .slider-specialist-desktop{
                        display: none;
                    }

                    .blogContent {
                        margin-top: -240px !important;
                    }

                    @media (min-width: 816px) {
                        .slider-specialist-desktop{
                            display: block;
                        }
                        .slider-specialist-movil{
                            display: none;
                        }
                        .blogContent {
                            margin-top: 0 !important;
                        }
                    }
                `}
            </style>
        </NewLayout>
    )
}

export default IndustrialPage
