import React from "react"

// Styles
import styles from "../styles/scss/components/industrialFaqs.module.scss"

const industrialFaqs = ({
    question,
    answer,
    index,
    active,
    setActive
}) => {
    const isOpen = active === index;

    const onClick = () => {
        setActive && setActive(index);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
        }
    };

    return (
        <div className={styles.cardContainer}>
            <div className={styles.header}>
                <h3 className={styles.heading} id={`faq-${index}`}>
                    {question}
                </h3>
                <div 
                    onClick={onClick}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    aria-expanded={isOpen}
                    aria-controls={`faq-content-${index}`}
                    role="button"
                    aria-current={isOpen}
                    className={styles.button}
                >
                    <i className={`fa fa-${active === index ? 'angle-up' : 'angle-down'}`} />
                </div>
            </div>
            {isOpen && <div className={styles.description}>
                {answer}
            </div>}
        </div>
    )
}

export default industrialFaqs;