// Carousel Product Images

export const carouselProductImages = [
    {
        id: 0,
        src: "/img/specialist/blueCarouselCans/Gel Lube.png",
        alt: "WD-40 Specialist® Gel Lube",
    },
    {
        id: 1,
        src: "/img/specialist/blueCarouselCans/Silicone.png",
        alt: "WD-40 Specialist® Silicone",
    },
    {
        id: 2,
        src: "/img/specialist/blueCarouselCans/WLG.png",
        alt: "WD-40 Specialist® White Lithium Grease",
    },
    {
        id: 3,
        src: "/img/specialist/blueCarouselCans/Penetrant-straw.png",
        alt: "WD-40 Specialist® Penetrant with EZ-REACH®",
    },
    {
        id: 4,
        src: "/img/specialist/blueCarouselCans/Penetrant.png",
        alt: "WD-40 Specialist® Penetrant",
    },
    {
        id: 5,
        src: "/img/specialist/blueCarouselCans/Contact Cleaner.png",
        alt: "WD-40 Specialist® Contact Cleaner",
    },
    {
        id: 6,
        src: "/img/specialist/blueCarouselCans/Dry Lube.png",
        alt: "WD-40 Specialist® Dry Lube",
    },
    {
        id: 7,
        src: "/img/industrial-page/carousel/EZ Reach.png",
        alt: "WD-40® EZ-REACH®",
    },
    {
        id: 8,
        src: "/img/industrial-page/carousel/1 Gallon.png",
        alt: "WD-40® Multi-Use Product, Gallon",
    },
    {
        id: 9,
        src: "/img/industrial-page/carousel/Industry.png",
        alt: "WD-40® Multi-Use Product Industrial Size",
    },
    {
        id: 10,
        src: "/img/industrial-page/carousel/Trigger Pro.png",
        alt: "WD-40® Trigger Pro®",
    },
    {
        id: 11,
        src: "/img/industrial-page/carousel/5 Gallon.png",
        alt: "WD-40® Multi-Use Product, 5 Gallon",
    },   
    {
        id: 12,
        src: "/img/industrial-page/carousel/2 way spray.png",
        alt: "WD-40® Smart Straw®",
    },
    {
        id: 13,
        src: "/img/industrial-page/carousel/Big Blast.png",
        alt: "WD-40® Big Blast®",
    },
]

// Collapse Product Images
export const collapseProductImages = index => {
    switch (index) {
        case 0:
            //Gel Lube
            return "/img/industrial-page/collapse-menu/gellube.png"
        case 1:
            //Silicone
            return "/img/industrial-page/collapse-menu/silicone.png"
        case 2:
            //White Lithium Grease
            return "/img/industrial-page/collapse-menu/whitelithium.png"
        case 3:
            //Penetrant with Flexible Straw
            return "/img/industrial-page/penetrantflexiblestraw3.png"
        case 4:
            //Penetrant
            return "/img/industrial-page/collapse-menu/penetrant.png"
        case 5:
            //Contact Cleaner
            return "/img/industrial-page/collapse-menu/contactcleaner.png"
        case 6:
            //Dry Lube
            return "/img/industrial-page/collapse-menu/drylube.png"
        case 7:
            //Non Aerosol
            return "/img/industrial-page/collapse-menu/nonaerosol.png"
        case 8:
            //EZ reach
            return "/img/industrial-page/collapse-menu/ezreach.png"
        case 9:
            //Multiuse One Gallon
            return "/img/industrial-page/collapse-menu/gallon1.png"
        case 10:
            //Multiuse Industrial
            return "/img/industrial-page/collapse-menu/industrial.png"
        case 11:
            //Multiuse Trigger Pro
            return "/img/industrial-page/collapse-menu/trigger.png"
        case 12:
            //Multiuse 5 Gallon
            return "/img/industrial-page/collapse-menu/gallon5.png"
        case 13:
            //Multiuse Big Blast
            return "/img/industrial-page/collapse-menu/bigblast.png"
        case 14:
            //Multiuse Smart Straw
            return "/img/industrial-page/collapse-menu/smartstraw.png"
        case 15:
            //Multiuse Gallon 55
            return "/img/industrial-page/gallon55-1.png"
        default:
            return "/img/industrial-page/collapse-menu/gellube.png"
    }
}

// Product Icons
export const productIcons = index => {
    switch (index) {
        case 0:
            //Gel Lube
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/linkages.png", "Linkages"],
                ["/img/specialist/cables.png", "Cables"],
            ]
        case 1:
            //Silicone
            return [
                ["/img/specialist/linkages.png", "Linkages"],
                ["/img/specialist/pulleys.png", "Pulleys"],
                ["/img/specialist/cables.png", "Cables"],
            ]
        case 2:
            //White Lithium Grease
            return [
                ["/img/specialist/hinges.png", "Hinges"],
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/doortracks.png", "Door Tracks"],
            ]
        case 3:
            //Penetrant with Flexible Straw
            return [
                ["/img/specialist/locks.png", "Locks"],
                ["/img/specialist/nuts.png", "Nuts"],
                ["/img/specialist/threads.png", "Threads"],
            ]
        case 4:
            //Penetrant
            return [
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/locks.png", "Locks"],
                ["/img/specialist/nuts.png", "Nuts"],
            ]
        case 5:
            //Contact Cleaner
            return [
                ["/img/specialist/circuitboards.png", "Circuit Boards"],
                ["/img/specialist/electricpanels.png", "Electric Panels"],
                ["/img/specialist/controls.png", "Controls"],
            ]
        case 6:
            //Dry Lube
            return [
                ["/img/specialist/powertools.png", "Power Tools"],
                ["/img/specialist/blades.png", "Blades"],
                ["/img/specialist/bits.png", "Bits"],
            ]
        case 7:
            //EZ reach
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]
        case 8:
            //Multiuse One Gallon
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]
        case 9:
            //Multiuse Industrial
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]
        case 10:
            //Multiuse Trigger Pro
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]
        case 11:
            //Multiuse 5 Gallon
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]        
        case 12:
            //Multiuse Smart Straw
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]
        case 13:
            //Multiuse Big Blast
            return [
                ["/img/specialist/gears.png", "Gears"],
                ["/img/specialist/threads.png", "Threads"],
                ["/img/specialist/hinges.png", "Hinges"],
            ]
        default:
            return [
                ["/img/specialist/gears.png", "gears"],
                ["/img/specialist/linkages.png", "linkages"],
                ["/img/specialist/cables.png", "cables"],
                ["/img/specialist/bearings.png", "bearings"],
            ]
    }
}

// New Product Copy
export const productCopy = index => {
    switch (index) {
        case 0:
            //Gel Lube
            return {
                body: `<p class="p1">WD-40 Specialist Gel Lube No-Drip Formula offers long lasting lubrication. Designed for superior protection that resists water and displaces moisture to prevent rust for up to one year.* This spray gel lubricant goes on thick and stays where you spray, making it ideal for use on vertical surfaces and offers non-fling for moving parts such as gears, bearings, cables, chains, rollers and more. Once applied, it withstands temperature ranges from -100°F to 500°F. Safe to use on multiple surfaces including metals, plastic and rubber.</p>
            <p class="p2">*Independent tests conducted in salt spray and humidity chamber. Results may vary depending on extreme conditions.</p>
            <p class="p2">** Test Lab: Petro-Lubricant Laboratories Inc. (Lafayette, NJ): ASTM D5620A: Test date: 04/2020</p>
            <p class="p2">*** Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM B117; Test date: 03/2020</p>
            <ul class="ul1">
                <li class="li3">Lubricates 15X longer than the competition**</li>
                <li class="li3">Protects against rust and surface corrosion up to 6X longer than the competition***</li>
                <li class="li3">Perfect for vertical surfaces</li>
                <li class="li3">50-state VOC compliant</li>
                <li class="li1"><a href="https://www.datocms-assets.com/10845/1595966233-wd-40-specialist-gel-lube-tds-sheet.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>
                <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-spray-and-stay-gel-lubricant-aerosol-us-ghs-sds.pdf?_gl=1*akwi2k*_gcl_au*MTYzODEzNTY4My4xNzIyOTk0MzA4*_ga*Nzk0MDk5MDI2LjE3MjI5OTQzMDk.*_ga_DDQGDT9LXF*MTcyMzA1NzkzMS4yLjEuMTcyMzA4NjU2My42MC4wLjA." target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
            </ul>`,
            }

        case 1:
            //Silicone
            return {
                body: `
                <p class="p1">WD-40 Specialist® Silicone Lubricant safely lubricates, resists water and protects metal and non-metal surfaces such as rubber, plastic and vinyl. Once dried, this formula leaves a clear, non-staining film that doesn't stick or make a mess, so it won't attract dirt. This silicone lubricant spray is great for use on cables, pulleys, guide rails, valves, linkages, hinges, locks and more. Effective in-use temperatures ranging from -100° F to 500° F.</p>
                <p class="p2">* Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM D4172; Test date: 12/2020</p>
                <p class="p2">** Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM D4172- Modified; Test date: 03/2021</p>
                <ul class="ul1">
                    <li class="li1">Provides up to 5X greater wear protection than the competition.* Our formula delivers the ultimate lubrication for less frequent reapplications.</li>
                    <li class="li1">Lubricates at least 2X longer than the competition**</li>
                    <li class="li1">Lubricates, resists water and protects metal and non-metal surfaces</li>
                    <li class="li1">50-state VOC compliant</li>
                    <li class="li1">NSF Category H2 (#146863)</li>      
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1595966317-wd-40-specialist-silicone-tds-sheet.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>              
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-water-resistant-silicone-lubricant-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 2:
            //White Lithium Grease
            return {
                body: `
                <p class="p1">WD-40 Specialist® White Lithium Grease is ideal for metal-to-metal applications that require heavy-duty lubrication and leaves behind a rust-inhibiting barrier for protection against corrosion. This long-lasting formula won't melt, freeze, or run in harsh weather conditions. Also great for lubricating equipment before placing in storage. Sprays on easily with a thick, protective coating for outdoor and automotive hinges, gears, sprockets, latches, door tracks, pulleys, cables and guide rails. Safe to use on metals. Effective in temperatures ranging from 0° F to 300° F for unbeatable protection.</p>
                <ul class="ul1">
                    <li class="li1">Ideal for metal-to-metal applications</li>
                    <li class="li1">50-state VOC compliant</li>
                    <li class="li1">NSF Category H2 (#146864)</li>     
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1595973773-wd-40-specialist-white-lithium-grease-tds-sheet.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>               
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-protective-white-lithium-grease-low-voc-us-ghs-sds.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 3:
            //Penetrant with Flexible Straw
            return {
                body: `
                <p class="p1">WD-40 Specialist® Penetrant is a fast-acting penetrant with capillary action to penetrate deep into crevices, threads and seams to break the rust bonds that hold stuck parts together. It is a low odor formula that works on contact to free rusted nuts, bolts, threads, locks, and chains leaving behind a protective layer that prevents rust and corrosion from re-forming. Now the job is even easier with our proprietary 8” flexible straw that bends and keeps its shape, allowing unlimited access to hard-to-reach places.</p>
                <p class="p2">* Test Lab: Fred Cuneo (3rd Party); Nail Climb Test; Test date: 03/2021.</p>
                <p class="p2">** Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM B117; Test date: 02/2021.</p>
                <ul class="ul1">
                    <li class="li1">Penetrates up to 2x faster than the competition*</li>
                    <li class="li1">Prevents rust 10x longer than the competition**</li>
                    <li class="li1">Penetrates rust to free up stuck, frozen, or seized equipment and parts</li>
                    <li class="li1">Safe to use on metal, rubber and plastic parts</li>
                    <li class="li1">Ideal for use on nuts, bolts, threads and locks</li>
                    <li class="li1">50-state VOC compliant</li>     
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1639505960-wd-40-specialist-penetrant-with-ezr-tds-sheet-11-9-21.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>               
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-rust-release-penetrant-spray-with-straw-us-ghs-sds.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 4:
            //Penetrant
            return {
                body: `
                <p class="p1">A fast-acting penetrant with capillary action to penetrate deep into crevices, threads and seams to break the rust bonds that hold stuck parts together.

WD-40 Specialist® Penetrant is a fast-acting penetrant with capillary action to penetrate deep into crevices, threads and seams to break the rust bonds that hold stuck parts together. It is a low odor formula that works on contact to free rusted nuts, bolts, threads, locks, and chains leaving behind a protective layer that prevents rust and corrosion from re-forming. It can also Spray 2 Ways®- up for stream and down for a wider spray. </p>
                <p class="p2">* Test Lab: Fred Cuneo (3rd Party); Nail Climb Test; Test date: 03/2021.</p>
                <p class="p2">** Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM B117; Test date: 02/2021.</p>
                <ul class="ul1">
                    <li class="li1">Penetrates up to 2x faster than the competition*</li>
                    <li class="li1">Prevents rust 10x longer than the competition**</li>
                    <li class="li1">Penetrates rust to free up stuck, frozen, or seized equipment and parts</li>
                    <li class="li1">Safe to use on metal, rubber and plastic parts</li>
                    <li class="li1">Ideal for use on nuts, bolts, threads and locks</li>
                    <li class="li1">50-state VOC compliant</li>    
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1595966284-wd-40-specialist-penetrant-tds-sheet.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>                
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-rust-release-penetrant-spray-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 5:
            //Contact Cleaner
            return {
                body: `
                <p class="p1">WD-40 Specialist® Contact Cleaner, featuring patented Smart Straw technology, blasts away oil, dirt, flux residue and condensation from sensitive electronics and electrical equipment with pinpoint precision. It dries quickly, leave no residue and removes over 95% of common surface contaminants, making it the best at cleaning electrical contacts and for optimal performance of equipment. Safe and ideal for use on printed circuit boards, controls, battery terminals, switches, precision instruments and electric panels.</p>
                <p class="p2">*Test on plastic before using, and avoid adhesives, device screens, and keyboards. Test Lab: UL Canton, MA; Surface Compatibility; Test date 7/2018</p>
                <p class="p2">**Always check with the manufacturer before performing maintenance on devices. Test Lab: Sterling Laboratories (Toledo, OH); ASTM D4488; Test date 8/2018</p>
                <ul class="ul1">
                    <li class="li1">Safe to use on electrical equipment, most plastics*, rubber and metal</li>
                    <li class="li1">Cleans sensitive electronics equipment and removes over 95% of common surface contaminants for optimal performance**</li>
                    <li class="li1">Leaves no residue</li>
                    <li class="li1">50-state VOC compliant</li>
                    <li class="li1">NSF Nonfood Compounds Program Listed K2 (#146857)</li>       
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1636603355-wd-40-specialist-contact-cleaner-tds-sheet-11-10-21.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>             
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1596555551-wd-40-specialist-contact-cleaneringredients.pdf" target="_new">What's In it &gt;&gt;</a></li>
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-electrical-contact-cleaner-spray-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 6:
            //Dry Lube
            return {
                body: `
                <p class="p1">WD-40 Specialist® Dry Lube with PTFE is a dry lubricant that keeps your equipment running smoothly with long-lasting corrosion protection that does not attract dirt, dust, or oil. It provides superior lubrication and long-lasting corrosion protection with no oily residue. Best used where cleanliness and maximum performance are required as it dries quick leaving behind a lubricating film that resists dirt, dust and oil buildups. Great for reducing friction and wear on blades and bits, door and window tracks, slides, conveyor belts, rollers, hinges, table saws, lathes, power tools and equipment. Effective in temperatures ranging from -50°F to 500°F.</p>
                <p class="p2">* Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM D4172; Test date: 12/2020.</p>
                <p class="p2">**Test Lab: WD-40 Co. Technical Center (Pine Brook, NJ); ASTM D4172– Modified; Test date: 04/2021.</p>
                <ul class="ul1">
                    <li class="li1">Provides 5x greater wear protection than the competition for longer-lasting lubrication and reduced application frequency*</li>
                    <li class="li1">Lubricates at least 4x longer than the competition**</li>
                    <li class="li1">Safe to use on wood, paper, fabrics, leather, metal, rubber and most plastics (except clear polycarbonate and polystyrene).</li>
                    <li class="li1">NSF Category H2 (#146856)</li>    
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1595973744-wd-40-specialist-dry-lube-tds-sheet.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>                
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/specialist/wd-40-specialist-dirt-dust-resistant-dry-lube-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 7:
            //EZ reach
            return {
                body: `
                <p class="p1">WD-40 Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. WD-40 EZ-REACH features an attached 8-inch flexible straw that bends and keeps its shape to get into hard-to-reach places.</p>
                <ul class="ul1">
                    <li class="li1">Original WD-40 formula</li>
                    <li class="li1">8" straw bends and keeps its shape</li>
                    <li class="li1">Sprays 2 Ways®</li>
                    <li class="li1">50-state VOC compliant</li>    
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602174882-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>                
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-aerosol-low-voc-sds-us-ghs.pdf?_gl=1*2jk6g*_gcl_au*MTYzODEzNTY4My4xNzIyOTk0MzA4*_ga*Nzk0MDk5MDI2LjE3MjI5OTQzMDk.*_ga_DDQGDT9LXF*MTcyMzA1NzkzMS4yLjEuMTcyMzA4NTE1MC42MC4wLjA." target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 8:
            //Multiuse One Gallon
            return {
                body: `
                <p class="p1">WD-40® Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. WD-40 Multi-Use Product One Gallon is ideal for heavy jobs that require soaking and dipping.</p>
                <ul class="ul1">
                    <li class="li2">Original WD-40 formula</li>
                    <li class="li2">Ideal for soaking and dipping</li>
                    <li class="li2">Heavy-duty use</li>
                    <li class="li2">50-state VOC compliant</li>         
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602175843-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>           
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-bulk-low-voc-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 9:
            //Multiuse Industrial
            return {
                body: `
                <p class="p1">WD-40 Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. WD-40 Multi-Use Product Industrial Size is perfect for big jobs that require a large amount of WD-40 Multi-Use Product in one place.</p>
                <ul class="ul1">
                    <li class="li2">Original WD-40 formula</li>
                    <li class="li2">Traditional spray nozzle</li>
                    <li class="li2">Unique size for industrial use</li>
                    <li class="li2">50-state VOC compliant</li>        
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602175124-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>            
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-aerosol-low-voc-sds-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 10:
            //Multiuse Trigger Pro
            return {
                body: `
                <p class="p1">WD-40 Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. WD-40 Trigger Pro® has the same great formula, and is in a non-aerosol format that provides more control, less mess, and easy disposal.</p>
                <ul class="ul1">
                    <li class="li2">Original WD-40 formula</li>
                    <li class="li2">Non-aerosol</li>
                    <li class="li2">Durable spray trigger</li>
                    <li class="li2">50-state VOC compliant</li>         
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602175787-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>           
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-trigger-pro-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 11:
            //Multiuse 5 Gallon
            return {
                body: `
                <p class="p1">WD-40 Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. WD-40 Multi-Use Product 5-Gallon Pail is best used for heavy duty industrial jobs uses that require repeated treatment.</p>
                <ul class="ul1">
                    <li class="li2">Original WD-40 formula</li>
                    <li class="li2">Heavy-duty industrial size</li>
                    <li class="li2">Ideal for soaking and dipping</li>
                    <li class="li2">50-state VOC compliant</li>       
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602175203-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>             
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-bulk-low-voc-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 12:
            //Multiuse Smart Straw
            return {
                body: `
                <p class="p1">WD-40 Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. Its permanently attached Smart Straw sprays 2 ways®. Available in 8 oz., 11 oz., and 12 oz. can sizes.</p>
                <ul class="ul1">
                    <li class="li2">Original WD-40 formula</li>
                    <li class="li2">Smart Straw Sprays 2 Ways</li>
                    <li class="li2">Flip up for stream, down for spray</li>
                    <li class="li2">50-state VOC compliant</li>     
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602175378-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>               
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-aerosol-low-voc-sds-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        case 13:
            //Multiuse Big Blast
            return {
                body: `
                <p class="p1">WD-40 Multi-Use Product protects metal from rust and corrosion, penetrates stuck parts, displaces moisture and lubricates almost anything. WD-40 Big Blast® features a wide-area spray nozzle, designed to deliver product quickly and efficiently over large areas.</p>
                <ul class="ul1">
                    <li class="li2">Original WD-40 formula</li>
                    <li class="li2">Wide area nozzle spray</li>
                    <li class="li2">Covers large areas efficiently</li>
                    <li class="li2">50-state VOC compliant</li>        
                    <li class="li1"><a href="https://www.datocms-assets.com/10845/1602175909-wd-40-mup-tds-sheet-10-5-20.pdf" target="_new" style="color: #1c3687 !important;"> Technical Data Sheet &gt;&gt;</a></li>            
                    <li class="li1" style="display:flex; flex-direction:row; gap:9px; align-items:center; list-style: none; margin-left: -12px;"><img src="/img/sds.png" alt="SDS" /><a href="https://files.wd40.com/pdf/sds/mup/wd-40-multi-use-product-aerosol-low-voc-sds-us-ghs.pdf" target="_new" style="color: #1c3687 !important;">View the Safety Data Sheet &gt;&gt;</a></li>
                </ul>
            `,
            }
        default:
            return {
                body: `
                <p class="p1">Missing Product Detail information</p>
            `,
            }
    }
}

// Product Images
export const productImages = index => {
    switch (index) {
        case 0:
            //Gel Lube
            return [
                { url: "/img/industrial-page/gellube1.png" },
                { url: "/img/industrial-page/gellube2.png" },
                { url: "/img/industrial-page/gellube3.png" },
            ]
        case 1:
            //Silicone
            return [
                { url: "/img/industrial-page/silicone1.png" },
                { url: "/img/industrial-page/silicone2.png" },
                { url: "/img/industrial-page/silicone3.png" },
                { url: "/img/industrial-page/silicone4.png" },
            ]
        case 2:
            //White Lithium Grease
            return [
                { url: "/img/industrial-page/whitelithium1.png" },
                { url: "/img/industrial-page/whitelithium2.png" },
                { url: "/img/industrial-page/whitelithium3.png" },
            ]
        case 3:
            //Penetrant with Flexible Straw
            return [
                { url: "/img/industrial-page/penetrantflexiblestraw1.png" },
                { url: "/img/industrial-page/penetrantflexiblestraw2.png" },
                { url: "/img/industrial-page/penetrantflexiblestraw3.png" },
                { url: "/img/industrial-page/penetrantflexiblestraw4.png" },
                { url: "/img/industrial-page/penetrantflexiblestraw5.png" },
            ]
        case 4:
            //Penetrant
            return [
                { url: "/img/industrial-page/penetrant1.png" },
                { url: "/img/industrial-page/penetrant2.png" },
                { url: "/img/industrial-page/penetrant3.png" },
                { url: "/img/industrial-page/penetrant4.png" },
            ]
        case 5:
            //Contact Cleaner
            return [
                { url: "/img/industrial-page/contactcleaner1.png" },
                { url: "/img/industrial-page/contactcleaner2.png}" },
                { url: "/img/industrial-page/contactcleaner3.png" },
                { url: "/img/industrial-page/contactcleaner4.png" },
            ]
        case 6:
            //Dry Lube
            return [
                { url: "/img/industrial-page/drylube1.png" },
                { url: "/img/industrial-page/drylube2.png" },
                { url: "/img/industrial-page/drylube3.png" },
                { url: "/img/industrial-page/drylube4.png" },
                { url: "/img/industrial-page/drylube5.png" },
            ]
        case 7:
            //EZ reach
            return [
                { url: "/img/industrial-page/ezreach1.png" },
                { url: "/img/industrial-page/ezreach2.png" },
                { url: "/img/industrial-page/ezreach3.png" },
                { url: "/img/industrial-page/ezreach4.png" },
            ]
        case 8:
            //Multiuse One Gallon
            return [
                { url: "/img/industrial-page/gallon1-1.png" },
                { url: "/img/industrial-page/gallon1-2.png" },
                { url: "/img/industrial-page/multiuse.png" },
            ]
        case 9:
            //Multiuse Industrial
            return [
                { url: "/img/industrial-page/industrial1.png" },
                { url: "/img/industrial-page/industrial2.png" },
                { url: "/img/industrial-page/industrial3.png" },
                { url: "/img/industrial-page/multiuse.png" },
            ]
        case 10:
            //Multiuse Trigger Pro
            return [
                { url: "/img/industrial-page/trigger1.png" },
                { url: "/img/industrial-page/trigger2.png" },
                { url: "/img/industrial-page/trigger3.png" },
                { url: "/img/industrial-page/multiuse.png" },
            ]
        case 11:
            //Multiuse 5 Gallon
            return [
                { url: "/img/industrial-page/gallon5-1.png" },
            ]        
        case 12:
            //Multiuse Smart Straw
            return [
                { url: "/img/industrial-page/smartstraw1.png" },
                { url: "/img/industrial-page/smartstraw3.png" },
                { url: "/img/industrial-page/smartstraw2.png" },                
                { url: "/img/industrial-page/multiuse.png" },
            ]
        case 13:
            //Multiuse Big Blast
            return [
                { url: "/img/industrial-page/bigblast1.png" },
                { url: "/img/industrial-page/bigblast2.png" },
                { url: "/img/industrial-page/bigblast3.png" },
                { url: "/img/industrial-page/multiuse.png" },
            ]
        default:
            return [
                { url: "/img/industrial-page/silicone1.png" },
                { url: "/img/industrial-page/silicone2.png" },
                { url: "/img/industrial-page/silicone3.jpg" },
            ]
    }
}

// Product Videos
export const productVideos = index => {
    switch (index) {
        case 0:
            //Gel Lube
            return [
                {
                    video: { providerUid: "clhv5VB4xr8" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-gellube.jpg",
                },
            ]
        case 1:
            //Silicone
            return [
                {
                    video: { providerUid: "rdmSd8kX1Zk" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-silicone.jpg",
                },
            ]
        case 2:
            //White Lithium Grease
            return [
                {
                    video: { providerUid: "uAh2n_JwpYo" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-whitelithium.jpg",
                },
            ]
        case 3:
            //Penetrant with Flexible Straw
            return [
                {
                    video: { providerUid: "sepQbwHuEjE" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-penetrantflexbilestraw.jpg",
                },
            ]
        case 4:
            //Penetrant
            return [
                {
                    video: { providerUid: "VJ1Q2n2deSI" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-penetrant.jpg",
                },
            ]
        case 5:
            //Contact Cleaner
            return [
                {
                    video: { providerUid: "s8cKP104Dnc" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-contactcleaner.jpg",
                },
            ]
        case 6:
            //Dry Lube
            return [
                {
                    video: { providerUid: "ZV_zth6pUbI" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-drylube.jpg",
                },
            ]
        case 7:
            //EZ reach
            return [
                {
                    video: { providerUid: "IPI1ZNmDmfc" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-ezreach.jpg",
                },
            ]
        case 8:
            //Multiuse One Gallon
            return null;
        case 9:
            //Multiuse Industrial
            return [
                {
                    video: { providerUid: "lZeM6wp6ycw" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-industrial.jpg",
                },
            ]
        case 10:
            //Multiuse Trigger Pro
            return [
                {
                    video: { providerUid: "yPNfWVyECEA" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-trigger.jpg",
                },
            ]
        case 11:
            //Multiuse 5 Gallon
            return null;        
        case 12:
            //Multiuse Smart Straw
            return [
                {
                    video: { providerUid: "au5XK5175sY" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-smartstraw.jpg",
                },
            ]
        case 13:
                //Multiuse Big Blast
            return [
                {
                    video: { providerUid: "kPpxeFIL7Wg" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-bigblast.jpeg",
                },
            ]
        default:
            return [
                {
                    video: { providerUid: "clhv5VB4xr8" },
                    thumbnail:
                        "/img/industrial-page/thumbnails/thumbnail-gellube.jpeg",
                },
            ]
    }
}

// Anchorss PDFs to download
export const anchorpdfs = [
    {
        text: "Industrial: Transportation Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Transportation%20Industry%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: Wind Turbine Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Wind%20Turbine%20Industry%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: Railway Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Railway%20Industry%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: Farming Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Farm%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: Plumbing Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Plumbing%20Industry%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: Metal Fabrication Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Metal%20Fabrication%20Industry%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: HVAC Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/HVAC%20Sell%20Sheet%20920%20FINAL.pdf",
    },
    {
        text: "Industrial: Marine Solutions >>",
        url:
            "https://files.wd40.com/Industrial%20Page/Marine%20Industry%20Sell%20Sheet%20521%20FINAL.pdf",
    },
]

export const becomeAuthorizedDistributorSection = {
    url: "/contact/distributor/",
    title: "Industry Specific Solutions",
    subtitle: "to Become and Authorized Distributor in the USA",
}

export const images = [
    {
        id: 0,
        src: "/img/industrial-page/transportation.png",
        title: "TRANSPORTATION",
    },
    {
        id: 1,
        src: "/img/industrial-page/oil-gas.png",
        title: "OIL /GAS /CHEMICAL",
    },
    {
        id: 2,
        src: "/img/industrial-page/mining.png",
        title: "MINING / AGGREGATE",
    },
    {
        id: 3,
        src: "/img/industrial-page/forest.png",
        title: "FOREST / PAPER",
    },
    {
        id: 4,
        src: "/img/industrial-page/power.png",
        title: "POWER & UTILITIES",
    },
    {
        id: 5,
        src: "/img/industrial-page/government.png",
        title: "GOVERNMENT",
    },
    {
        id: 6,
        src: "/img/industrial-page/warehouse.png",
        title: "WAREHOUSE & LOGISTICS",
    },
    {
        id: 7,
        src: "/img/industrial-page/metal.png",
        title: "METAL / METAL FABRICATION",
    },
    {
        id: 8,
        src: "/img/industrial-page/commercial.png",
        title: "COMMERCIAL / CONSTRUCTION",
    },
]

export const faqs = [
    {
        question: "Does WD-40 Brand offer a full line of products?",
        answer: "Yes, WD-40 Brand provides a full line of solutions designed to meet various industrial needs. Unlike many competitors who use similar formulas with different names, WD-40 Brand simplifies your product selection with straightforward, effective options.",
    },
    {
        question: "How do WD-40 Brand solutions address most industrial applications?",
        answer: "WD-40 Brand solutions cover the 80/20 rule of applications, meaning we address the most common needs effectively. While competitors often rename similar formulas for specific uses, our straightforward approach helps you find the right product faster.",
    },
    {
        question: "Can WD-40 Brand help streamline my inventory and reduce SKUs?",
        answer: "Absolutely. WD-40 Brand offers a Site Assessment Form to analyze your current product usage. By completing this form, we can recommend solutions that consolidate manufacturers and SKUs, simplifying your inventory without compromising on quality.",
    },
    {
        question: "Are there cost-saving benefits with using WD-40 Brand products?",
        answer: "Yes, WD-40 Brand products provide cost savings in two main ways: upfront savings and long-term reduction in Total Cost of Ownership. Our solutions are designed to maximize value in both scenarios, helping you manage your budget effectively.",
    },
    {
        question: "Can WD-40 Brand help reduce the inventory space required?",
        answer: "Yes, we can help minimize the space needed for your inventory. The first step is to start with a site assessment so we can help identify current usage and redundant products. WD-40 Brand also offers space-saving solutions, such as various sizes of Multi-Use Product cans, the Precision Pen, and EZ-PODS, making them ideal for compact storage in tool belts, work trucks, and other job site locations.",
    },
    {
        question: "Does WD-40 Brand offer solutions to minimize operator error?",
        answer: "Yes, WD-40 Brand has several products designed to reduce operator error. Our Smart Straw and EZ-REACH delivery systems ensure precise application, targeting the right equipment and minimizing labor, especially in hard-to-reach areas. Additionally, our Degreaser & Cleaner EZ-PODS simplify the mixing process to avoid mistakes and utilizing more product than needed.",
    },
    {
        question: "Can WD-40 Brand solutions help reduce product consumption?",
        answer: "Yes, our innovative solutions are designed to reduce consumption compared to competitors. WD-40 Brand was the first to introduce the permanently attached Smart Straw, the flexible EZ-REACH straw, and the unique EZ-PODS for cleaners and degreasers. Our products also offer longer-lasting lubrication, corrosion protection, and faster acting penetration.",
    },
    {
        question: "Are WD-40 Brand products safe and compliant with regulations?",
        answer: "Absolutely. All WD-40 Brand products are 50-state VOC compliant, and none require a Prop 65 warning, making them safe for nationwide use. We also provide GHS-approved labels for solutions intended for use in a spray bottle.",
    },
    {
        question: "Does WD-40 Brand provide technical support for product selection and use?",
        answer: "Yes, WD-40 Brand offers expert assistance through our Technical Solutions Managers, who can help you choose the right product and ensure it's used correctly for optimal results.",
    },
]
