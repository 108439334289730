import React from "react"
import { Container, Row, Col } from "react-grid-system"

// Components
import Card from "./card"
import Title from "./title"

// Styles
import styles from "../styles/scss/components/relatedBlogPostsNew.module.scss"

const relatedBlogPostNew = ({
    blogs,
    isCustom
}) => (
    <Container className={styles.MainContainer} style={isCustom && {backgroundColor: '#1C3687' }}>
        <Row>
            <Col>
                <Title className={styles.Title} style={isCustom && {color: '#FFF200' }} value="Related blog posts" />
            </Col>
        </Row>
        <Row className={styles.cards}>
            {
                blogs[0] && (
                    <Col xs={12} sm={12} md={12} lg={isCustom ? 6 : 4}>
                        <Card
                            type="relatedBlog"
                            title={blogs[0].title}
                            description={blogs[0].teaser}
                            date={blogs[0].dateOfArticle}
                            image={blogs[0].heroImage.url}
                            link={blogs[0].slug} />
                    </Col>
                )
            }
            {
                blogs[1] && (
                    <Col xs={12} sm={12} md={12} lg={isCustom ? 6 : 4}>
                        <Card
                            type="relatedBlog"
                            title={blogs[1].title}
                            description={blogs[0].teaser}
                            date={blogs[0].dateOfArticle}
                            image={blogs[1].heroImage.url}
                            link={blogs[1].slug} />
                    </Col>
                ) 
            }
            {
                !isCustom && blogs[2] && (
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <Card
                            type="relatedBlog"
                            title={blogs[2].title}
                            description={blogs[0].teaser}
                            date={blogs[0].dateOfArticle}
                            image={blogs[2].heroImage.url}
                            link={blogs[2].slug} />
                    </Col>
                ) 
            }
        </Row>
    </Container>
)

export default relatedBlogPostNew;
